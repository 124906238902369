body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./images/BackgroundSVG.svg") no-repeat center center fixed;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
  width: 100%;
}

#id {
  height: 100%;
}
.App {
  text-align: center;
  padding: 24px;
  height: 100%;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
}

.magic-editor {
  color: black;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

#chemistryIcon {
  display: none;
}

#htmlEditor {
  min-width: 100px;
  height: 100%;
  border-radius: 16px;
  background-color: white;
  width: 100%;
  img {
    width: auto;
    scale: 1;
  }
}

.App-link {
  color: #61dafb;
}

.wrs_modal_close_button {
  display: none;
}
.wrs_modal_button_cancel {
  display: none;
}

.editor-btn {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-right: 24px;
}

.btn-primary {
  background-color: #7a5aed;
  border-color: #007bff;
  border: 0;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  color: white;
  cursor: pointer;
}

.wrs_modal_dialogContainer {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
